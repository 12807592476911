import template from "./smart-registration-enter-code.html"

(function ($, ko) {
    ko.components.register('smart-registration-enter-code', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new EnterCodeModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function EnterCodeModel(params, updateHeight) {
        const self = this;
        
        self.codeMaxLength = 10;
        
        self.selectedMethod = params.model.selectedMethod;

        self.sendCodeRequest = new $.cdh.post.smartRegistration.sendCode();
        self.validateCodeRequest = new $.cdh.post.smartRegistration.validateCode();
        
        self.isEmail = ko.pureComputed(function () {
           return self.selectedMethod() === $.cdh.enums.registrationSendCodeMethod.email;
        });

        self.isSms = ko.pureComputed(function () {
            return [$.cdh.enums.registrationSendCodeMethod.smsToMobileNumber, 
                $.cdh.enums.registrationSendCodeMethod.smsToPhone].includes(self.selectedMethod());
        });

        self.maskedEmail = params.model.maskedData.email;
        self.maskedNumber = ko.pureComputed(function() {
            switch (self.selectedMethod()) {
                case $.cdh.enums.registrationSendCodeMethod.smsToMobileNumber:
                    return params.model.maskedData.mobileNumber();
                case $.cdh.enums.registrationSendCodeMethod.smsToPhone:
                    return params.model.maskedData.phone();
            }
        });
        
        self.isLoading = ko.pureComputed(function() {
            return self.sendCodeRequest.isLoading() || self.validateCodeRequest.isLoading();
        });
        
        self.code = ko.observable();
        
        self.isCodeEntered = ko.pureComputed(function () {
            let code = self.code();
            return code && code.length > 0 && code.length <= self.codeMaxLength;
        });
        
        self.isErrorShowed = ko.observable(false);
        
        self.sendCode = function() {
            self.isErrorShowed(false);
            self.validateCodeRequest.load({code: self.code()}).done(function (result) {
                params.model.onNext(result);
            }).fail(function(xhr) {
                if(xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.IsTicketExpired) {
                    params.model.onNext({ Success: false, IsTicketExpired: true });
                } else {
                    updateHeight();
                }
            });
        };

        self.back = params.model.onBack;
        self.cancel = params.model.onCancel;
        self.noCodeReceived = params.model.onNoCodeReceived;
        
        self.resend = function() {
            if(self.isLoading()) {
                return;
            }
            params.model.clear();
            self.sendCodeRequest.load({method: self.selectedMethod()}).fail(function (xhr) {
                if(xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.Forbidden) {
                    params.model.onNext({ Success: false });
                } else {
                    self.isErrorShowed(true);
                    updateHeight();
                }
            });
        };
        
        params.model.clear = function() {
            self.code(null);
            self.isErrorShowed(false);

            self.sendCodeRequest.setData(null);
            self.validateCodeRequest.setData(null);
        };
    }
    
})(jQuery, ko);