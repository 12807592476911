import template from "./medicine-cabinet-prescription-editor-search.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet-prescription-editor-search', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PrescriptionEditorSearch(params.model, widgetInfo);
            }
        }
    });

    function PrescriptionEditorSearch(params, widgetInfo) {
        const self = this;

        self.drugsService = new $.cdh.http.smartAccount.vendors.DrugsService();
        
        self.forceHideEmptyMessage = ko.observable(true);
        self.searchValue = ko.observable().extend({
            rateLimit: {
                timeout: 500, method: "notifyWhenChangesStop"
            },
            required: true,
            minLength: 3,
        });
        self.drugs = ko.observableArray();
        self.selectedDrug = ko.observable();

        self.selectDrug = function(drug) {
            if(!drug.disabled) {
                self.selectedDrug(drug);
            }
        };

        let currentDrugRequest = null;
        // self.searchValue.subscribe(function (newSearchValue) {
           
        // });
        self.handleSearchSubmit = function () {
            var searchprescription = $("#searchprescription");
            searchprescription.html(" ");
            if(self.searchValue.isValid()) {
                self.forceHideEmptyMessage(false);
                self.selectedDrug(null);
                if(currentDrugRequest) {
                    self.drugsService.cancel();
                    currentDrugRequest = null;
                }
                
                currentDrugRequest = self.drugsService.searchAsync(self.searchValue());
               
                currentDrugRequest.then(function (result) {
                    let data = result.data();                    
                    searchprescription.html(data.length.toString() + " " + $.cdh.languageConstant.getConstant('button.results-found'));                   
                    let allPrescriptions = params.allPrescriptions();
                    
                    self.drugs(data.map(function (item) {
                        item.opened = ko.observable(false);
                        item.onToggle = function () {
                            item.opened(!item.opened());
                            widgetInfo.updateHeight();
                        };

                        item.Drugs.forEach(function (drug) {
                            drug.disabled = allPrescriptions.length !== 0 && allPrescriptions.some(function (prescription) {
                                return prescription.Drug.Ndc === drug.Ndc;
                            })
                        });
                        
                        return item;
                    }));
                    
                    widgetInfo.updateHeight();
                });
            }
        }
        
        self.canNext = ko.pureComputed(function () {
            return self.selectedDrug();
        });
        
        self.onNext = function () {
            params.onNext(self.selectedDrug());
        };

        self.onCancel = function () {
            params.closeFunc();
        };
    }

})(jQuery, ko);