(function (ko, $) {
    var ViewModel = function (updateHeight, template) {

        this.isVisible = ko.observable(false);

        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.employerName = '';

        this.vendor = '';
        this.employerSystemKey = '';
        this.tenantSystemKey = '';
        this.memberSystemKey = '';
        this.apiToken = '';
        this.isDemo = true;
        this.token = '';

        this.template = new $.cdh.services.templateExtractor(template);
        this.tpaStreamStatusRequest = new $.cdh.get.partners.tpaStreamStatus();

        var tpaStreamContent = document.getElementById('tpa-stream-content');

        //Included part of TPA stream feature
        this.secretKey = '';
        this.tpaConnectAccessTokenRequest = new $.cdh.post.partners.getConnectAccessToken();
        this.connectAccessToken = '';

        function mouseClick(elem) {
            if (!elem) return;

            const clickEvents = ['mousedown', 'click', 'mouseup'];
            clickEvents.forEach(function(e) {
                const mouseEvent = new MouseEvent(e, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                });
                elem.dispatchEvent(mouseEvent);
            });
        }

        function doneChoosePayer() {

            // widget attributes
            try {
                document.getElementById('tpa-stream-widget').setAttribute('role', 'region');
            } catch (e) {}

            // images
            try {
                const imagesWrapper = tpaStreamContent.querySelector('#payer-images');
                const links = imagesWrapper.querySelectorAll('a');
                const images = imagesWrapper.querySelectorAll('img');
                links.forEach(function (link) {
                    link.setAttribute('role', 'button');
                    link.setAttribute('tabindex', '0');
                    link.addEventListener('keydown', function (e) {
                        if (e.keyCode === 32 || e.keyCode === 13) {
                            e.preventDefault();
                            e.stopPropagation();
                            link.click();
                        }
                    });
                });
                images.forEach(function (image) {
                    let alt = '';
                    if (image.src.indexOf('aetna') !== -1) {
                        alt = 'Aetna';
                    } else if (image.src.indexOf('dental') !== -1) {
                        alt = 'Dental';
                    } else if (image.src.indexOf('vsp') !== -1) {
                        alt = 'Vsp';
                    }
                    image.setAttribute('alt', alt);
                })
            } catch (e) {}

            // Select
            try {
                const dropdown = tpaStreamContent.querySelector('#payer-dropdown');
                const searchInput = dropdown.querySelector('.ReactSelect__input > input');
                const uniqueId = Math.random().toString(16).slice(2);
                let timer;

                dropdown.setAttribute('role', 'combobox');
                dropdown.setAttribute('aria-expanded', 'false');
                if (dropdown.previousSibling && dropdown.previousSibling.tagName === 'H3') {
                    dropdown.previousSibling.setAttribute('id', 'ReactSelectLabely__' + uniqueId);
                    dropdown.setAttribute('aria-labelledby', 'ReactSelectLabely__' + uniqueId);
                }
                searchInput.setAttribute('role', 'textbox');
                searchInput.setAttribute('aria-multiline', 'false');
                searchInput.removeEventListener('keydown', keyboardHandler);
                searchInput.addEventListener('keydown', keyboardHandler);

                const observer = new MutationObserver(function (mutations) {
                    clearTimeout(timer);
                    timer = setTimeout(function () {
                        mutations.forEach(function (mutation) {
                            const menuContainer = dropdown.querySelector('.ReactSelect__menu');
                            if (!menuContainer) {
                                if (dropdown.getAttribute('aria-expanded') === 'true') {
                                    dropdown.setAttribute('aria-expanded', 'false');
                                }
                                if (searchInput.hasAttribute('aria-controls')) {
                                    searchInput.removeAttribute('aria-controls');
                                }
                                if (searchInput.hasAttribute('aria-activedescendant')) {
                                    searchInput.removeAttribute('aria-activedescendant');
                                }

                                return;
                            }

                            const menuList = menuContainer.querySelector('.ReactSelect__menu-list');
                            if (!menuList) return;

                            if (!menuList.hasAttribute('role')) {
                                menuList.setAttribute('role', 'listbox');
                            }
                            if (!menuList.hasAttribute('id')) {
                                menuList.setAttribute('id', 'ReactSelectList__' + uniqueId);
                            }
                            if (!dropdown.hasAttribute('aria-expanded') || dropdown.getAttribute('aria-expanded') === 'false' ) {
                                dropdown.setAttribute('aria-expanded', 'true');
                            }
                            if (!searchInput.hasAttribute('aria-controls')) {
                                searchInput.setAttribute('aria-controls', 'ReactSelectList__' + uniqueId);
                            }

                            const menuItems = menuList.querySelectorAll('.ReactSelect__option');
                            menuItems.forEach(function (item) {
                                if (!item.hasAttribute('role')) {
                                    item.setAttribute('role', 'option');
                                    item.removeEventListener('mouseover', onHoverMenuItem);
                                    item.addEventListener('mouseover', onHoverMenuItem);
                                    item.removeEventListener('click', onSelectMenuItem);
                                    item.addEventListener('click', onSelectMenuItem);
                                }
                            });
                        });
                    }, 100);
                });
                observer.observe(dropdown, { attributes: true, childList: true, characterData: true });

                function onSelectMenuItem(e) {
                    const prevSelecedItem = dropdown.querySelector('.ReactSelect__option[aria-selected="true"]');
                    if (prevSelecedItem) {
                        prevSelecedItem.removeAttribute('aria-selected');
                    }
                    this.setAttribute('aria-selected', 'true');
                }

                function onHoverMenuItem(e) {
                    if (this.hasAttribute('id')) {
                        searchInput.setAttribute('aria-activedescendant', this.getAttribute('id'));
                    }
                }

                function keyboardHandler(e) {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                        setTimeout(function () {
                            const focusingItem = dropdown.querySelector('.ReactSelect__option--is-focused');
                            if (focusingItem && focusingItem.hasAttribute('id')) {
                                searchInput.setAttribute('aria-activedescendant', focusingItem.getAttribute('id'));
                            }
                        });
                    }
                    if (e.keyCode === 13 || e.keyCode === 32) {
                        const prevSelecedItem = dropdown.querySelector('.ReactSelect__option[aria-selected="true"]');
                        if (prevSelecedItem) {
                            prevSelecedItem.removeAttribute('aria-selected');
                        }
                        const focusingItem = dropdown.querySelector('.ReactSelect__option--is-focused');
                        if (focusingItem) {
                            focusingItem.setAttribute('aria-selected', 'true');
                        }

                    }
                }
            } catch (e) {}
        }
        function doneTermsOfService() {
            // console.log('doneTermsOfService');
        }
        function doneCreatedForm() {

            // form novalidate
            try {
                document.getElementById('easy-enroll-form').noValidate = true;
            } catch (e) {}

            // back button
            try {
                const backButton = tpaStreamContent.querySelector('#easy-enroll-form-page svg.fa-arrow-circle-left');
                backButton.setAttribute('role', 'button');
                backButton.setAttribute('aria-label', 'Back');
                backButton.setAttribute('tabindex', '0');
                backButton.setAttribute('focusable', 'true');
                backButton.addEventListener('keydown', function (e) {
                    if (e.keyCode === 32 || e.keyCode === 13) {
                        e.preventDefault();
                        e.stopPropagation();
                        mouseClick(this);
                    }
                });
            } catch (e) {}

            // question button
            try {
                const uniqueId = Math.random().toString(16).slice(2);
                const questionButton = tpaStreamContent.querySelector('#easy-enroll-form-page svg.fa-question-circle');
                const answerContainer = tpaStreamContent.querySelector('#easy-enroll-form-page .mm-popup');
                questionButton.setAttribute('role', 'button');
                questionButton.setAttribute('aria-expanded', 'false');
                questionButton.setAttribute('tabindex', '0');
                questionButton.setAttribute('aria-controls', 'answerContainer__' + uniqueId);
                questionButton.setAttribute('focusable', 'true');
                answerContainer.setAttribute('id', 'answerContainer__' + uniqueId);

                questionButton.addEventListener('click', function questionButtonHandler(e) {
                    let interval = setInterval(function () {
                        const isOpen = answerContainer.classList.contains('mm-popup--visible');
                        questionButton.setAttribute('aria-expanded', isOpen.toString());
                        if (!isOpen) {
                            clearInterval(interval);
                            questionButton.focus();
                        }
                    }, 100);
                });

                questionButton.addEventListener('keydown', function (e) {
                    if (e.keyCode === 32 || e.keyCode === 13) {
                        e.preventDefault();
                        e.stopPropagation();
                        mouseClick(this);
                    }
                });
            } catch (e) {}

            // input fields
            try {
                function setAsRequired(id) {
                    const elem = document.getElementById(id);
                    if (!elem) return;
                    elem.required = true;

                    const label = tpaStreamContent.querySelector('label[for="' + id + '"]');
                    if (!label) return;
                    if (label.innerHTML.indexOf('*') === -1) {
                        label.innerHTML += '*';
                    }
                }

                document.getElementById('root_username').setAttribute('autocomplete', 'username');
                document.getElementById('root_password').setAttribute('autocomplete', 'current-password');

                const reqElems = [
                    'root_tenantAcknowledgement',
                    'root_security_question_first_choice',
                    'root_security_question_second_choice',
                    'root_security_question_third_choice'
                ];
                reqElems.forEach(function(id) {
                    setAsRequired(id);
                });
            } catch (e) {}

            // Error messages
            try {
                const formGroups = tpaStreamContent.querySelectorAll('.form-group');
                formGroups.forEach(function (group) {
                    const observer = new MutationObserver(function (mutations) {
                        if (!group.classList.contains('has-error')) return;

                        const errorContainer = group.querySelector('.error-detail').parentNode;
                        if (!errorContainer) return;

                        if (!errorContainer.hasAttribute('role')) {
                            errorContainer.setAttribute('role', 'alert');
                        }
                        if (!errorContainer.hasAttribute('aria-live')) {
                            errorContainer.setAttribute('aria-live', 'assertive');
                        }
                    });
                    observer.observe(group, { attributes: false, childList: true, characterData: true });
                });
            } catch (e) {}
        }
        function doneRealTime() {
            // console.log('doneRealTime');
        }
        function doneEasyEnroll() {

            // change headings
            try {
                const successWrapper = document.getElementById('finished-with-easy-enroll');
                const title = successWrapper.querySelector('h2');
                const newTitle = document.createElement('h3');
                newTitle.innerHTML = title.innerHTML;
                const parent = title.parentNode;
                successWrapper.setAttribute('aria-live', 'polite');
                parent.insertBefore(newTitle, title);
                parent.removeChild(title);
            } catch(e) {}
        }
        function donePopUp() {

            // GoToPayerSite button
            try {
                const btnWrapper = tpaStreamContent.querySelector('.mm-popup__box__footer__left-space');
                const btn = btnWrapper.querySelector('.mm-popup__btn');
                btn.setAttribute('role', 'link');
                btn.addEventListener('keyup', function(e) {
                    if (e.keyCode === 32) {
                        e.preventDefault();
                    }
                });
            } catch (err) { }
        }

        function doneSelectEnrollProcess() {
            //style after initilizatio;

            try {
                var selectEnroll = tpaStreamContent.querySelector('#select-enroll-process');

                selectEnroll.classList.add('custom-style');

                var enrollSVG = selectEnroll.querySelectorAll('.raise')[0].querySelector('svg');
                var fixCredSVG = selectEnroll.querySelectorAll('.raise')[1].querySelector('svg');

                enrollSVG.classList.add('enroll-icon');
                fixCredSVG.classList.add('fix-cred-icon');
            }
            catch (err) { console.error(err); }
        }

        function handleFormErrors() {
            // console.log('handleFormErrors');
        }


        this.initializeTpaStream = function () {
           
            if (typeof(window.StreamConnect) !== 'function') {
                return;
            }
            window.StreamConnect({
                el: '#tpa-stream-content',
                employer: {
                    vendor: ($.cdh.global.environment.toLowerCase() === "local" || $.cdh.global.environment.toLowerCase() === "qa" || $.cdh.global.environment.toLowerCase() === 'dev' || $.cdh.global.environment.toLowerCase() === 'demo')? "alegeus":this.vendor,
                    systemKey: this.employerSystemKey,
                    name: this.employerName
                },
                tenant: {
                    vendor: this.vendor,
                    systemKey: this.tenantSystemKey
                },
                user: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    //To avoid the masked email issue by passing member system key
                    email: this.memberSystemKey+this.email,
                    memberSystemKey: this.memberSystemKey
                },
                isDemo: this.isDemo,
                fixCredentials: true,
                enableInterop: true,
                apiToken: this.apiToken,
                connectAccessToken: this.token,
                realTimeVerification: true,
                doneChoosePayer: function () { doneChoosePayer() },
                doneTermsOfService: function () { doneTermsOfService() },
                doneCreatedForm: doneCreatedForm,
                doneRealTime: function () { doneRealTime() },
                // doneEasyEnroll: ({ employer, payer, tenant, policyHolder, user }) => {},
                doneEasyEnroll: function (params) {
                    // NOTE: use this arguments, for example:
                    // var employer = params.employer;
                    // var payer = params.payer;
                    // var tenant = params.tenant;
                    // var policyHolder = params.policyHolder;
                    // var user = params.user;
                    doneEasyEnroll(params);
                },
                donePopUp: function () { donePopUp() },
                doneSelectEnrollProcess: function () { doneSelectEnrollProcess()},
                // handleFormErrors: (error, {response, request, config}) => {},
                handleFormErrors: function (params) {
                    // NOTE: use this arguments, for example:
                    // var response = params.response;
                    // var request = params.request;
                    // var config = params.config;
                    handleFormErrors(params);
                    console.log(params);
                },
                userSchema: {}
            });


            // update all widgets height if tpa stream widget height has changed
            if (tpaStreamContent) {
                var tpaStreamContentHeight = tpaStreamContent.offsetHeight;
                setInterval(function () {
                    if (tpaStreamContent.offsetHeight !== tpaStreamContentHeight) {
                        tpaStreamContentHeight = tpaStreamContent.offsetHeight;
                        recalcWidgetsHeights();
                    }
                }, 1000);
            }
        }.bind(this);

       

        this.tpaStreamStatusRequest.load().done(function (tpaStreamStatus) {
            if (tpaStreamStatus.Disabled) {
                var widget = $("tpa-stream");
                $.cdh.helper.removeWidget(widget);
            } else {
               
                this.firstName = tpaStreamStatus.FirstName;
                this.lastName = tpaStreamStatus.LastName;
                this.email = tpaStreamStatus.Email;
                this.vendor = tpaStreamStatus.Vendor;
                this.memberSystemKey = tpaStreamStatus.MemberSystemKey;

                this.employerName = tpaStreamStatus.EmployerName;
                this.employerSystemKey = tpaStreamStatus.EmployerSystemKey;

                this.tenantSystemKey = tpaStreamStatus.TenantSystemKey;
               
                this.isDemo = tpaStreamStatus.Demo;
                this.apiToken = tpaStreamStatus.ApiToken;

               this.tpaConnectAccessTokenRequest.load().done(function (response) {                   
                   this.token = response.Data;
                   var sdkScript = document.createElement("script");
                   sdkScript.type = "text/javascript";
                   sdkScript.src = "https://app.tpastream.com/static/js/sdk.js";
                   sdkScript.onload = this.initializeTpaStream;
                   sdkScript.onreadystatechange = this.initializeTpaStream;
                   (document.getElementsByTagName('head')[0] || document.body).appendChild(sdkScript);
                   
               }.bind(this));
               
                this.isVisible(true);             
            }
          
            updateHeight();
        }.bind(this));

        this.isVisible.subscribe(updateHeight);
    };

    ko.components.register("tpa-stream", {
        template: {element: "template-tpa-stream"},
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new ViewModel(updateHeight, params.template);
            }
        }
    });
})(window.ko, window.jQuery);
