import template from './product-look-up.html';

(function ($, ko) {
    function ProductLookUp(params) {
        const self = this;
        let model = params.model;
        this.model = model;
    }

    ko.components.register('product-look-up', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ProductLookUp(params, componentInfo);
            }
        }
    });
})(jQuery, ko);