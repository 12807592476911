import template from "./smart-registration-create-user.html"

(function ($, ko) {
    ko.components.register('smart-registration-create-user', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new CreateUserModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function CreateUserModel(params, updateHeight) {
        const self = this;

        self.createUserRequest = new $.cdh.post.smartRegistration.createUser();

        const { MinUserIdLength, MaxUserIdLength } = params.validationArguments();
        const userNameValidationMessage = `Should be between ${MinUserIdLength} and ${MaxUserIdLength} chars`

        self.MinUserIdLength = ko.observable(MinUserIdLength);
        self.MaxUserIdLength = ko.observable(MaxUserIdLength);

        self.userMessageContent = `A valid userId must be between ${MinUserIdLength} and ${MaxUserIdLength} characters.<br/>Can contain Alphanumeric character.<br/>Can contain following special characters, @ (at sign) . (period) - (hyphen or dash)_ (underscore).`

        self.model = {
            userName: ko.observable().extend({
                required: true,
                betweenLengthString: { params: [MinUserIdLength, MaxUserIdLength], message: userNameValidationMessage },
                usernamevalidation: { params: true, message: 'Username contains invalid characters.' }
            }),
            email: ko.observable().extend({
                required: true, 
                email: true
            }),
            password: ko.observable(''),
            confirmPassword: ko.observable('').extend({required: true})
        };

        self.model.password.extend({
            passwordComplexity: { 
                userName: self.model.userName
            },
            notify: 'always'
        });

        self.model.confirmPassword.extend({
            equal: { params: self.model.password, message: 'Passwords do not match.' }
        });

        self.model.passwordStrength = ko.pureComputed(function () {
            // var password = self.model.password();
            // var result = 0;
            // if (/[a-z]/.test(password)) { result += 25; }
            // if (/[A-Z]/.test(password)) { result += 25; }
            // if (/\d/.test(password)) { result += 25; }
            // if (/[^a-zA-Z0-9 ]+/.test(password)) { result += 25; }
            // return result;

            function checkPassStrength(password){
                if (!password) {
                    return 0;
                }
                var strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
                var mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

                if(strongPassword.test(password)) {
                    return 100;
                } else if(mediumPassword.test(password)){
                    return 66.66;
                } else{
                    return 33.33;
                }
            }
            var password = self.model.password();
            return checkPassStrength(password);
        });

        self.validation = new $.cdh.validation(self.model);

        self.isDisabled = ko.observable(false);
        self.isLoading = ko.pureComputed(function() {
            return self.createUserRequest.isLoading();
        });
        
        self.isErrorShowed = ko.observable(false);

        self.back = params.model.onBack;
        self.cancel = params.model.onCancel;
        self.submit = function() {
            if (self.isLoading())
                return;

            self.validation.activate();

            if(!self.validation.hasErrors()) {
                self.isErrorShowed(false);
                self.createUserRequest.load(self.model).done(function (data) {
                    var userData = {};
                    userData.username = self.model.userName;
                    userData.password = self.model.password;
                    params.model.onNext(userData);
                }).fail(function (xhr) {
                    if(xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.AlreadyCreated) {
                        showAlertForExistAccount(xhr.responseJSON.message);
                    } else {
                        self.isErrorShowed(true);
                        updateHeight();
                    }
                })
            }
        };
        
        function showAlertForExistAccount(message) {
            function closeCallback() {
                document.getElementById('reg__step-3-next-btn').focus();
            }

            $.cdh.helper.alert2({
                message: message,
                okCallback: function () {
                    self.cancel();
                    closeCallback();
                }.bind(this),
                cancelCallback: function () {
                    closeCallback();
                }.bind(this),
                okText: "Sign in",
                okAttrs: {
                    role: 'link'
                },
                cancelText: "Close"
            });
        }
        
        params.model.disable = function() {
           self.isDisabled(true); 
        };

        params.model.clear = function() {
            self.isDisabled(false);
            self.isErrorShowed(false);
            
            self.model.password('');
            self.model.confirmPassword('');
            
            self.model.password.isModified(false);
            self.model.confirmPassword.isModified(false);

            self.createUserRequest.setData(null);
        };
    }

})(jQuery, ko);