import {DataServiceBase} from "../../DataServiceBase";

(function ($, ko) {

    class DrugsService extends DataServiceBase{
        constructor() {
            super('/SmartAccount/Vendors/Drugs');
        }

        searchAsync(partName) {
            return this.getAsync({partName: partName}, "Search");
        };

        searchPricingAsync(ndc, equivalentNdc, zipCode, distance) {
            let data = {
                ndc: ndc,
                equivalentNdc: equivalentNdc,
                zipCode: zipCode,
                distance: distance
            };
            return this.getAsync(data, "Pricing");
        };
    }
    
    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    DrugsService: DrugsService
                }
            }
        }
    });

})(jQuery, ko);