import template from './merchant-locator.html';

(function ($, ko) {
    function MerchantLocator(params) {
        const self = this;
        let model = params.model;
        this.model = model;
        this.distanceOptions = ko.observableArray([
            { label: "5 miles", value: 5 },
            { label: "10 miles", value: 10 },
            { label: "15 miles", value: 15 },
            { label: "20 miles", value: 20 },
            { label: "25 miles", value: 25 }
        ]);
        this.selectedDistance = ko.observable();
    }

    ko.components.register('merchant-locator', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new MerchantLocator(params);
            }
        }
    });
})(jQuery, ko);
