import template from "./smart-registration-add-employee.html"

ko.components.register('smart-registration-add-employee', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo) {
            return new SmartRegistrationAddEmployee(params, updateContainerHeight(componentInfo.element));
        }
    }
});

function SmartRegistrationAddEmployee(params, updateHeight) {
    const self = this;
    
    self.employerListRequest = new $.cdh.get.registration.employerList();
    self.employeeIdNotesRequest = new $.cdh.get.registration.employeeIdNotes();
    self.addEmployeeDetailsRequest = new $.cdh.post.smartRegistration.addEmployeeDetails();
    
    self.allowSelectEmployerByName = params.model.allowSelectEmployerByName;
    self.employeeIdLabel = ko.observable();
    self.employeeIdHelpText = ko.observable();
    
    self.model = {
        employerId: ko.observable().extend({
            required: true,
            rateLimit: {
                timeout: 500, method: "notifyWhenChangesStop"
            }
        }),
        employeeId: ko.observable().extend({required: true})
    };

    self.validation = new $.cdh.validation(self.model);

    self.employerFieldSettings = {
        load: function (query, callback) {
            if(self.employerListRequest.isLoading()) {
                callback();
                return;
            }

            self.employerListRequest.load({ searchText: query}).done(callback);
            updateHeight();
        },
        getOptionsFromResponse: function(data) {
            if (!data) {
                return [];
            }

            return data.map(function (item) {
               return {
                   value: item.Id,
                   text: item.Text
               };
            });
        },
        onChange: function (id) {
            self.model.employerId(id);

            if(id) {
                self.loadEmployeeIdNotes(id);
            }

            updateHeight();
        }
    };
    
    self.model.employerId.subscribe(newValue => {
        if (!self.allowSelectEmployerByName && newValue) {
            self.loadEmployeeIdNotes(newValue);
        }
    });
    
    self.isLoading = ko.pureComputed(function() {
       return self.addEmployeeDetailsRequest.isLoading() || self.employerListRequest.isLoading() || self.employeeIdNotesRequest.isLoading();
    });

    self.loadEmployeeIdNotes = function(id) {
        if(self.employeeIdNotesRequest.isLoading()) {
            return;
        }

        self.employeeIdLabel(null);
        self.employeeIdHelpText(null);

        self.employeeIdNotesRequest
            .load({employerId: id})
            .done(result => {
                if (result) {
                    self.employeeIdLabel(result.EmployeeIDLabel);
                    self.employeeIdHelpText(result.EmployeeIDHelpText);
                }
            })
            .fail();

        updateHeight();
    };

    self.back = params.model.onBack;
    self.cancel = params.model.onCancel;
    self.submit = function() {
        if (self.isLoading())
            return;
        
        self.validation.activate();
        
        if(!self.validation.hasErrors()) {
            self.addEmployeeDetailsRequest.load(self.model).done(function (data) {
                params.model.onNext(data);
            }).fail(xhr => {
                let errorMessage = xhr.responseJSON && xhr.responseJSON.message ? xhr.responseJSON.message : "Internal Error";
                params.model.onNext({ErrorMessage: errorMessage});
            });
        }
    };
    
    params.model.clear = function() {
        self.addEmployeeDetailsRequest.setData(null);
        self.model.employerId(null);
        self.model.employeeId(null);

        // clear autocomplete field
        $('.employer-name-select .autocomplete-field_text-field').val('');
        $('.employer-name-select .autocomplete-field_list').find('li').remove();


        self.validation.deactivate(true);
    };
}