import template from "./account-activity-open-manual-claim.html"

(function ($, ko) {
    ko.components.register('account-activity-open-manual-claim', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityOpenManualClaim(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityOpenManualClaim(params, updateHeight) {
        const self = this;
        let model = params.model;
        
        self.isMobile = params.isMobile;
        
        self.manualClaimDetailsModel = {
            Status: model.Status,
            EOBExtra: [{
                Id: model.TransactionKey,
                SettlementDate: model.SettlementDate,
                SeqNumber: model.SeqNumber
            }],
            color: "color" + params.color(),
            TrackingNumber: model.ClaimId,
            Claimant: model.Claimant,
            TransactionDate: model.Date,
            ServiceStartDate: model.ServiceStartDate,
            Comments: model.Description
        };
    }

})(jQuery, ko);