import template from "./smart-registration-review.html"

(function ($, ko) {
    ko.components.register('smart-registration-review', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ReviewQuestionsModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ReviewQuestionsModel(params, updateHeight) {
        
        const userData = params.userData;
        this.firstName = userData.firstName();
        this.lastName = userData.lastName(); 
        this.confirmEmail = ko.observable(userData.email()).extend({ required: true, email: true });
        this.confirmEmailValidation = new $.cdh.validation(this.confirmEmail);
        this.confirmEmailValidation.activate();
        this.clickCancel = params.model.onCancel;
        this.showResultSetup = function () {
            if (!this.confirmEmailValidation.hasErrors()) {
                params.model.onNext({ Success: true, ConfirmEmail: this.confirmEmail });
            }
        }.bind(this);
    }

})(jQuery, ko);