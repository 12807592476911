(function ($) {

  


    var cardDependentModel = function (dependent, updateHeight, onSuccessCallback) {
        this.data = dependent;
        this.updateHeight = updateHeight;
        this.isOpened = ko.observable(false);

        this.onSuccessCallback = onSuccessCallback;
        if (!this.onSuccessCallback)
            this.onSuccessCallback = $.noop;

        this.fullName = ko.computed(function () {
            return ko.unwrap(this.data.FirstName) + " " + ko.unwrap(this.data.LastName);
        }.bind(this));

        this.requestCard = function () {
            $.cdh.helper.alert2({
                title: $.cdh.languageConstant.getConstant('profile.issue-new-debit-card-confirmation'),
                hasCloseBtn: true,
                message: $.cdh.languageConstant.getConstant("profile.issue-debit-card-for", { '0': this.fullName() }),
                okCallback: function () {
                    $.cdh.helper.waitAlert($.cdh.languageConstant.getConstant("profile.issue-debit-card-wait-message", { '0': this.fullName() }));

                    var newCardRequest = new $.cdh.post.cards.new();
                    newCardRequest.load({id: this.data.DependentId})
                        .done(function () {
                            $.cdh.helper.closePopup();
                            this.onSuccessCallback(); 
                            $.cdh.helper.alert($.cdh.languageConstant.getConstant("profile.debit-card-issued-successfully-for", { '0': this.fullName() }), null, null, $.cdh.languageConstant.getConstant("messages.success"), null, null);
                        }.bind(this))
                        .fail(function (resp) {
                            $.cdh.helper.closePopup();
                            $.cdh.helper.alert(resp.responseJSON.message, null, null, "Failed to issue a new debit card");
                        });
                }.bind(this),
                okText: $.cdh.languageConstant.getConstant('profile.issue-card')
            });
        }.bind(this);
        var breakPoint = 768
        this.openCard = function () {
            if (window.innerWidth < breakPoint) {
                this.isOpened(!this.isOpened());
            }      
        }.bind(this);

        this.cardRowKeyDown = cardRowKeyDown;
        function cardRowKeyDown(card, e) {
            if (window.innerWidth < breakPoint && (e.keyCode === 32 || e.keyCode === 13)) {
                this.isOpened(!this.isOpened());
                return false;
            }
            return true;
        };



    };


    
    $.extend(true, $, {
        cdh: {
            models: {
                CardDependent: cardDependentModel
            }
        }
    });

})(jQuery);