import template from "./claim-medical-details.html"

(function ($, ko) {
    ko.components.register('claim-medical-details', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimMedicalDetails(params, widgetInfo.updateHeight);
            }
        }
    });

    function ClaimMedicalDetails(params, updateHeight) {
        let eobSummary = params.model;
        this.eobSummary = eobSummary;

        this.setClose = function (closer) {
            this.close = closer;
        }.bind(this);
        // Rollback wcp-4044
        /* this.isYourResponsibilityZero = ko.observable(false);*/
        this.amount = ko.observable(null);
        this.mostRecentServiceDate = ko.observable(null);
        this.medicalDetails = new $.cdh.get.claims.medicalDetails();
        this.medicalDetails.load({transactionKeys: eobSummary.EOBExtra});
        this.medicalDetails.data.subscribe(function (eob) {
            var total = eob.Total.TotalAmounts;
            this.amount(eob.Total.AmountWithOffset);
            this.mostRecentServiceDate = this.mostRecentServiceDate(eob.MostRecentServiceDate);
            var resp = total.Responsibility;
            var remb = total.Reimbursed;
            if (resp == 0.0 && remb == 0.0)
                remb = 0.001;
            this.chartData = [{
                value: resp, label: $.cdh.languageConstant.getConstant('field.responsibility') },  {
                value: remb,
                label: $.cdh.languageConstant.getConstant('ClaimDetail.ReimburseByBenefitAcct')
            }];
            //  Rollback wcp-4044
            /*this.isYourResponsibilityZero = ko.observable(eob.Total.DeductibleAmount.Responsibility === 0
                && eob.Total.CoinsuranceAmount.Responsibility === 0
                && eob.Total.CopayAmount.Responsibility === 0
            );*/
        }.bind(this));

        this.filteredTransactions = ko.pureComputed(function () {
            var prevGroup = null;
            return $.grep(this.medicalDetails.data().Transactions, function (t) {
                if (prevGroup == null || prevGroup.ClaimLine != t.ClaimLine) {
                    prevGroup = t;
                    return true;
                }
                return false;
            });
        }, this);


        this.paid = function (t) {
            return t.IsProviderPaid;
        }.bind(this);

        this.paidTo = function () {
            var result = null;
            var transactions = this.medicalDetails.data().Transactions;
            if (transactions.length > 0) {
                result = this.paid(transactions[0]) ? 'Yes' : 'No';
            }

            return result;
        }.bind(this);

        this.transactionPaidTo = function (t) {
            if (this.eobSummary.Status() === "Denied") {
                return '';
            }
            return this.paid(t) ? 'Provider' : 'Member';
        }.bind(this);

        this.shortAccountTypeDescription = function (t) {
            let accountTypeDesc = t.AccountTypeDescription;
            return accountTypeDesc && accountTypeDesc.length > 12 ? accountTypeDesc.substr(0, 12) + '...' : accountTypeDesc;
        }.bind(this);

        this.method = function (t) {
            if (this.eobSummary.Status() === "Denied") {
                return '';
            }
            var result = '';
            var rm = t.ReimbursementMethod;
            var check = t.CheckTraceNumber;
            if (rm == 'External Check' || rm == 'External Direct Deposit') {
                result = $.cdh.languageConstant.getConstant('ClaimDetail.PaidToProvider');
            } else if (rm == 'Direct Deposit' || rm == 'None') {
                result = rm;
            } else if ($.type(check) == 'string' && check.length > 0) {
                if (rm == 'Check' || rm == 'Direct Deposit') {
                    result = rm + ': ' + check;
                }
            } else {
                result = $.cdh.languageConstant.getConstant('ClaimDetail.PendingReimbursement');
            }
            return result;
        }.bind(this);

        this.dashPrint = function (l, r) {
            var display = l != null ? l : '';

            if (r != null && r.length > 0) {
                if (display.length > 0)
                    display += ' - ';
                display += r;
            }
            return display;
        }.bind(this);

        this.close = params.closeFunc || function () {};
    }
    
})(jQuery, ko);