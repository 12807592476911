import template from "./popup-zipcode.html"


ko.components.register('popup-zipcode', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PopupZipcode(params, updateContainerHeight(componentInfo.element));
        }
    }
});

function PopupZipcode(params, updateHeight) {
    const self = this;
    self.selectedInsurance = ko.observable(params.model.carrierName());
    self.selectedInsuranceUuid = ko.observable();
    self.insuranceList = ko.observableArray(params.model.insuranceList());
    self.planList = ko.observableArray(params.model.currentPlanList());
    self.selectedPlan = ko.observable(params.model.selectedPlan());
    self.filteredUuids = [];
    self.usertext = ko.observable(params.model.carrierName());
    self.isSubmitting = ko.observable(false);
    self.submitText = ko.observable($.cdh.languageConstant.getConstant("button.save"));

    self.InsurancesService = new $.cdh.http.smartAccount.vendors.InsurancesService;

    self.usertext.subscribe(function (newValue) {
        if (newValue === '') {
            self.planList([]);
            self.selectedPlan(null);
        }
    });

    self.enableSubmitButton = ko.pureComputed(
        () => !this.isSubmitting() && this.selectedPlan()
        );

    function filterInsuranceUuid(displayName) {

        self.filteredUuids = [];
        self.selectedInsuranceUuid(null);
        for (var item of self.insuranceList()) {
            if (item.carrierName == self.selectedInsurance() && item.displayName == displayName) {
                self.filteredUuids.push(item.uuid);
            }
        }
        if (self.filteredUuids.length > 0) {
            self.selectedInsuranceUuid(self.filteredUuids.toString());
        }

    };

    if (self.selectedPlan()) {
        filterInsuranceUuid(self.selectedPlan());
    }

    self.clickInsurancePlan = function (data, e) {
        if ($.trim($("#employer_name").val()) && $('select[name="dropdownInsurancePlan"]').find('option').length < 2) {
            alert("First select the Insurance provider from the dropdown. Type minimum three characters and wait for results to populate.");
            $("#employer_name").val("");
        }
    };
    self.onlyAlphabets = function (data, event) {
        return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32;
    };
        self.clickSubmit = function () {

        self.isSubmitting(true);
        self.submitText($.cdh.languageConstant.getConstant("button.saving"));

        self.InsurancesService.updateInsuranceAsync({
            uuid: self.selectedInsuranceUuid(),
            Details: self.insuranceList().find(x => x.uuid === self.selectedInsuranceUuid())
        }).then(function (data) {
            self.isSubmitting(false);
            params.model.carrierName(self.selectedInsurance());
            params.model.selectedPlan(self.selectedPlan());
            params.model.selectedInsuranceUuid(self.selectedInsuranceUuid());
            params.model.currentPlanList(self.planList());
            if (params.type == 'ProviderListModel') {
                self.insuranceInfo = {
                    insuranceIds: self.selectedInsuranceUuid(),
                    selectedPlan: self.selectedPlan()
                }
                params.model.storeInsuranceData(self.insuranceInfo);
                params.model.loadData(1);
            }
            self.submitText('save');
            params.closeFunc();
        });

    };

    self.clickCancel = function() {
        params.closeFunc();
    };

    self.selectedPlan.subscribe(function (displayName) {
        filterInsuranceUuid(displayName);
    });

    self.insurances = {
        load: function (query, callback) {

            // should be making plan list as blank before searching the new query
            self.planList([]);
            self.InsurancesService.searchAsync(1, 1000, query).then(function (result) {                
                let insuranceData = result.data().Data
                self.insuranceList(insuranceData);
                callback(insuranceData);
            })
        updateHeight();
        },
      
    getOptionsFromResponse: function (data) {
        if (!data) {
            return [];
        }

        let carriers = new Set();
        let resultData = [];

        for (var item in data) {
            if (!carriers.has(data[item].carrierName)) {
                carriers.add(data[item].carrierName);
                if (data[item].carrierName.toLowerCase().startsWith(self.usertext()?.toLowerCase())) {
                    resultData.push({ value: data[item].carrierName, text: data[item].carrierName });
                }
            }
        }

        return resultData;
    },
        onChange: function (carrierName) {
            if (carrierName)
            {
                self.selectedInsurance(carrierName);

                let planListSet = new Set();

                let filteredInsurances = self.insuranceList().filter(insurance => insurance.carrierName === carrierName);
                self.planList([]);

                for (var item in filteredInsurances) {
                    if (!planListSet.has(filteredInsurances[item].displayName)) {
                        self.planList.push(filteredInsurances[item].displayName);
                        planListSet.add(filteredInsurances[item].displayName);
                    }
                }

                if (self.planList().length > 0) {
                    self.planList(self.planList().sort(function (left, right) {
                        return left == right ? 0 : (left < right ? -1 : 1);
                    }));
                }

            }
        updateHeight();
    }
};

}

