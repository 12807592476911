import template from "./account-activity-mark-as-paid.html";

(function ($, ko) {
    ko.components.register('account-activity-mark-as-paid', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityMarkAsPaid(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityMarkAsPaid(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.isMobile = params.isMobile || false;
        self.isPaidOutOfPocket = ko.observable(model.Status() === "Paid Out-Of-Pocket");
        self.isEligibleForReimbursement = ko.observable(model.Status() === 'Eligible For Reimbursement');

        self.detailsButtonId = 'fx-claim-details-button-' + model.ClaimId;
        self.disable = model.Disable;
        
        self.reloadListCallback = params.reloadListCallback;

        function setPaidOutOfPocked() {
            $.cdh.helper.alert2({
                title: $.cdh.customLabels.markAsPaidOption,
                message: $.cdh.customLabels.markAsPaidOptionPopupText,
                isImpersonated: $.cdh.user.impersonated,
                cancelVisible: true,
                okCssClass: 'button-subm button-width-auto min-width-standard',
                okText: $.cdh.customLabels.markAsPaidOption,
                cancelText: $.cdh.languageConstant.getConstant('button.cancel'),
                buttonsBlockCssClass: 'popup_buttons--flex',
                okAttrs: $.cdh.user.impersonated ? { disabled: 'disabled' } : {},
                okCallback: function () {
                    self.disable(true);
                    new $.cdh.post.claims.fxClaimMarkAsPaid()
                        .load({externalClaimId: model.ClaimId})
                        .done(function () {
                            self.disable(false);
                            self.reloadListCallback();
                        });
                }
            });
        }

        function reversePaidOutOfPocket() {
            $.cdh.helper.alert2({
                title: $.cdh.customLabels.reverseMarkAsPaidOption,
                message: $.cdh.customLabels.reverseMarkAsPaidOptionPopupText,
                cancelVisible: true,
                okText: "Continue",
                okCallback: function () {
                    
                    new $.cdh.get.claims.fxDetails()
                        .load({ ExternalClaimId: model.ClaimId })
                        .then(function (data) {
                            var OutOfPocketAmounts = [];
                            $.each(data.Transactions, function (key, value) {
                                OutOfPocketAmounts.push({ ClaimLine: value.ClaimLine, Amount: 0 });
                            });
                            return OutOfPocketAmounts;
                        }).then(function (outOfPocketAmounts) {
                            new $.cdh.post.claims.update()
                                .load({ ExternalClaimId: model.ClaimId, OutOfPocketAmounts: outOfPocketAmounts })
                                .done(function () {

                                    self.reloadListCallback();
                                });
                        })
                }
            });

        }

        function togglePaidOutOfPocked() {
            if (self.isPaidOutOfPocket()) {
                reversePaidOutOfPocket();
            } else {
                setPaidOutOfPocked();
            }
        }

        self.setPaidOutOfPocket = function (data, event) {
            event.stopPropagation();
            togglePaidOutOfPocked();
        };

        self.onKeyDown = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.stopPropagation();
                togglePaidOutOfPocked();
            }
            return true;
        };
    }

})(jQuery, ko);