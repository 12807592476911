import template from "./account-activity-hsa-billpay-payment-details.html"

ko.components.register('account-activity-hsa-billpay-payment-details', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PaymentDetails(params, widgetInfo);
        }
    }
});

export class PaymentDetails {
    constructor(params, widgetInfo) {
        let model = params.model;

        this.total = model.FullAmount;
        this.payee = model.Provider;
        this.checkNumber = model.CheckNumber;
        this.denialReason = model.HsaBillPayInfo?.ErrorDescription;
        this.nextPaymentDate = model.HsaBillPayInfo?.NextPaymentDate;
    }
}