import template from "./find-care-base.html"


(function ($, ko) {   
    ko.components.register('find-care-base', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new FindCareBase(params.template, widgetInfo);
            }
        }
    });
        function FindCareBase(params, widgetInfo) {

            const self = this;

            self.disabled = false;
            self.showData = ko.observable(false);
            self.typeOfCare = ko.observable("");
            self.selectedPlan = ko.observable();
            self.zipCode = ko.observable($.cdh.helper.urlParam('zipcode')).extend({ required: true, zipCode: true });
            self.zipCodeValidation = new $.cdh.validation(this.zipCode);
            self.distance = ko.observable(parseInt($.cdh.helper.urlParam('distance')));
            self.selectedInsuranceUuid = ko.observable();
            self.carrierName = ko.observable();
            self.currentPlanList = ko.observableArray();
            self.insuranceList = ko.observable();
            self.searchbox = ko.observable(false);
            self.procedureSearch = ko.observable(false);
            self.selectedSpecialist = ko.observable();
            self.selectedProcedure = ko.observable();
            self.selectedPrescription = ko.observable();

            if ($.cdh.helper.urlParam('speciality')) {
                self.selectedSpecialist({ UUID: $.cdh.helper.urlParam('specialityId'), name: $.cdh.helper.urlParam('speciality') });
            }

            if ($.cdh.helper.urlParam('procedure')) {
                self.selectedProcedure({ UUID: $.cdh.helper.urlParam('procedureId'), name: $.cdh.helper.urlParam('procedure') });
            }

            //if ($.cdh.helper.urlParam('prescription')) {
            //    self.selectedPrescription({ UUID: $.cdh.helper.urlParam('prescriptionId'), name: $.cdh.helper.urlParam('prescription') });
            //}

            self.usertext = ko.observable();
            self.InsurancesService = new $.cdh.http.smartAccount.vendors.InsurancesService;
            self.participantService = new $.cdh.http.smartAccount.ParticipantService();
            self.SpecialtyService = new $.cdh.http.smartAccount.vendors.SpecialtyService();
            self.ProcedureService = new $.cdh.http.smartAccount.vendors.ProcedureService();
            self.DrugsService = new $.cdh.http.smartAccount.vendors.DrugsService();
            self.specialists = {
                load: function (query, callback) {

                    self.SpecialtyService.searchAsync(1, 1000, query).then(function (result) {
                        let specialists = result.data().Results;
                        callback(specialists);
                    })
                   
                },

                getOptionsFromResponse: function (data) {
                    if (!data) {
                        return [];
                    }

                    let resultData = data.map(item => {
                        return { value:  { UUID: item.UUID, name: item.Display }, text: item.Display }
                    });

                    return resultData;
                },

                onChange: function (specialist) {                    
                    if (specialist) {
                        self.selectedSpecialist(specialist);
                    }
                }
            };
            

            self.displaySearch = function (data, event) {

                //Remove all selected items and selecxt current item
                $('.autocomplete-field_text-field').bind('copy paste cut', function (e) {
                    e.preventDefault();
                });

                let selectedFinCare = $(event.target.closest('a')).attr('data-type');
                $(".home-screen-item").removeClass('selected-find-care');
                $(event.target).closest('.home-screen-item').addClass('selected-find-care');

                $(".find-care-content").removeClass('show-content');
                $("#" + selectedFinCare).addClass('show-content');

                if (selectedFinCare === 'primary-care' || selectedFinCare === 'urgent-care')
                    self.searchFindCare(selectedFinCare, event);
               
            };            

            self.procedures = {
                load: function (query, callback) {

                    self.ProcedureService.searchAsync(1, 1000, query).then(function (result) {
                        let procedures = result.data().data;
                        callback(procedures);
                    })

                },

                getOptionsFromResponse: function (data) {
                    if (!data) {
                        return [];
                    }

                    let resultData = data.map(item => {
                        return { value: { UUID: item.uuid, name: item.display }, text: item.display }
                    });

                    return resultData;
                },
                onChange: function (procedure) {
                    if (procedure) {
                        self.selectedProcedure(procedure);
                    }
                }
            };

            self.drugs = {
                load: function (query, callback) {
                    self.DrugsService.searchAsync(query).then(function (result) {
                        let drugs = JSON.parse(result.xhr.response)[0].Drugs;
                        callback(drugs);
                    })

                },

                getOptionsFromResponse: function (data) {
                    if (!data) {
                        return [];
                    }

                    let resultData = data.map(item => {
                        return { value: { UUID: item.Ndc, name: item.Title }, text: item.Title }
                    });

                    return resultData;
                },
                onChange: function (drug) {
                    if (drug) {
                        self.selectedPrescription(drug);
                    }
                }
            };

            self.searchFindCare = function (data, event) {
   
                self.zipCodeValidation.activate();
                if (!self.zipCodeValidation.hasErrors()) {
                    let type = event.target.dataset.type != undefined ? event.target.dataset.type : data;
                    let url =(type === 'urgent-care') ? $.cdh.routes.predefined.findcare.urgentcareList + '?zipcode=' + this.zipCode() + '&distance=' + this.distance() : (type === 'prescription-cost') ? $.cdh.routes.predefined.findcare.drugsList + '?zipcode=' + this.zipCode() + '&distance=' + this.distance() : $.cdh.routes.predefined.findcare.providerList + '?zipcode=' + this.zipCode() + '&distance=' + this.distance();

                    if (type === 'specialist' && this.selectedSpecialist()) {
                        url += '&specialityId=' + this.selectedSpecialist().UUID + '&speciality=' + this.selectedSpecialist().name ;
                    }
                    else if (type === 'procedure-cost' && this.selectedProcedure() ) {
                        url += '&procedureId=' + this.selectedProcedure().UUID + '&procedure=' + (this.selectedProcedure().name);
                    }
                    else if (type === 'prescription-cost' && this.selectedPrescription()) {
                        url += '&ndcs=' + this.selectedPrescription().UUID + '&drug=' + this.selectedPrescription().name;
                    }

                    window.location.href = url;
                }
            };
          
            self.displayPlanText = ko.pureComputed(
                () => this.selectedPlan() || $.cdh.languageConstant.getConstant("find-care.display-plantext")
            );

            self.displayZipCode = ko.pureComputed(
                () => self.zipCode() || $.cdh.languageConstant.getConstant("field.zip-code")

            );

            self.onlyAlphabets = function (data,event) {               
                return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
            };
            self.enableSubmit = function (data, event) {               
                if (event.target.value == '')
                    $(event.target).closest('.find-care-content').find("button[type='submit']").prop('disabled', true);                
            }
           
             function load() {
                 self.participantService.getDetailsAsync().then(function (result) {
                     let data = result.data();
                     let InsuranceInformation = data.Details.InsuranceInformation;

                     if (InsuranceInformation) {
                         self.selectedInsuranceUuid(InsuranceInformation.uuid);
                         self.carrierName(InsuranceInformation.Details?.carrierName);
                         self.selectedPlan(InsuranceInformation.Details?.displayName);
                     }

                     if (data.FindCareDisabled) {
                         self.disabled = true;
                     }
                     else {
                         self.showData(true);
                     }
                 }).then(function (data) {

                     if (self.carrierName()) {
                         self.InsurancesService.searchAsync(1, 1000, self.carrierName).then(function (result) {
                             let planListSet = new Set();
                             let filteredInsurances = result.data().Data.filter(insurance => insurance.carrierName === self.carrierName());

                             self.insuranceList(filteredInsurances);
                             self.currentPlanList([]);

                             for (var item in filteredInsurances) {
                                 if (!planListSet.has(filteredInsurances[item].displayName)) {
                                     self.currentPlanList.push(filteredInsurances[item].displayName);
                                     planListSet.add(filteredInsurances[item].displayName);
                                 }
                             }

                             if (self.currentPlanList().length > 0) {
                                 self.currentPlanList(self.currentPlanList().sort(function (left, right) {
                                     return left == right ? 0 : (left < right ? -1 : 1);
                                 }));
                             }

                         });
                     }
                     else {
                         self.InsurancesService.completed(true);
                     }

                 });
                widgetInfo.updateHeight();
            }
            load();
           }

    })(jQuery, ko);





