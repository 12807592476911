import template from './account-activity.html';

ko.components.register('account-activity', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountActivity(params, widgetInfo);
        }
    }
});

function AccountActivity(params, widgetInfo) {
    const updateHeight = widgetInfo.updateHeight;
    const self = this;
    const urlParams = {
        accountId: parseInt($.cdh.helper.urlParam('accountId')),
        page: $.cdh.helper.urlParam('page'),
        claimKey: $.cdh.helper.urlParam('claimKey'),
        extClaimId: $.cdh.helper.urlParam('extClaimId'),
        claimId: $.cdh.helper.urlParam('claimId'),
        transactionKey: $.cdh.helper.urlParam('transactionKey'),
        settlementDate: $.cdh.helper.urlParam('settlementDate'),
        expenseKeys: $.cdh.helper.urlParam('expenseKeys')
    };

    if (urlParams.expenseKeys) {
        urlParams.expenseKeys = JSON.parse(decodeURIComponent(urlParams.expenseKeys));
    }

    self.forceAddReceipt = ko.observable(urlParams.page === 'AddReceipt' &&
        (urlParams.claimKey || urlParams.claimId || urlParams.expenseKeys || urlParams.transactionKey || urlParams.settlementDate));

    self.addExpenseAllowed = $.cdh.brandData.DisplayAddExpense;
    self.submitClaimAllowed = $.cdh.employee.submitClaimAllowed();
    self.useExpensePortal = $.cdh.tpa.useExpensePortal;
    self.useTwoButtons = !self.useExpensePortal && self.addExpenseAllowed && self.submitClaimAllowed;
    self.useOneButton = !self.useTwoButtons && (self.addExpenseAllowed || self.submitClaimAllowed);
    self.add = () => {
        location.href = (self.submitClaimAllowed && self.useExpensePortal) ? $.cdh.routes.predefined.claims.portal :
            (self.addExpenseAllowed ? $.cdh.routes.predefined.expenses.add : $.cdh.routes.predefined.claims.submit);
    };

    self.claimSubmitText = ko.pureComputed(() => {
        let anyHsaCurrent = $.cdh.accounts.anyHsaCurrent();
        let anyManualClaimsEnableCurrent = $.cdh.accounts.anyManualClaimsEnabled();

        return (anyHsaCurrent && !anyManualClaimsEnableCurrent) ? $.cdh.languageConstant.getConstant('link.bill-pay-hsa') : $.cdh.languageConstant.getConstant('link.submit-claim');
    });

    self.expandItem = urlParams?.claimId?.length > 0 ? ko.observable(true) : ko.observable(false);
    self.activityItems = ko.observableArray();
    self.reassociatedAccounts = ko.observableArray();
    self.visibleHistorical = ko.pureComputed(() => self.reassociatedAccounts().length > 0);

    self.years = ko.observableArray();
    self.simplifiedStatuses = ko.observableArray();
    self.activityTypes = ko.observableArray();
    self.accountDescriptions = ko.observableArray();
    self.statuses = ko.observableArray();
    self.claimForMarkingAvailable = ko.observable(false);
    self.filterMarkAsPaid = ko.observable(false);
    self.submitEnabled = ko.observable(false);
    self.selectedClaimsForMarking = ko.observableArray();
    self.AccountActivityLabel = {
        submit: $.cdh.languageConstant.getConstant('button.submit'),
        submitting: $.cdh.languageConstant.getConstant('button.submitting'),
        mark_as_paid_message: $.cdh.languageConstant.getConstant('AccountDetail.markAsPaidMessage'),
        cancel: $.cdh.languageConstant.getConstant('button.cancel')
    }
    self.submitText = ko.observable(self.AccountActivityLabel.submit);
    self.error = ko.observable();


    self.filter = {
        isHistorical: ko.observable(false),
        reassociatedAccountId: ko.observable(),
        year: ko.observable(),
        simplifiedStatus: ko.observable(),
        status: ko.observable(),
        type: ko.observable(),
        dateFrom: ko.observable(),
        dateTo: ko.observable(),
        amount: new $.cdh.models.amountRange(),
        accountDescription: ko.observable(),
        claimId: ko.observable(urlParams.claimId),
        provider: ko.observable(),
        needToMarkPaid: ko.observable()
    };

    self.sort = {
        direction: ko.observable($.cdh.enums.sortDirection.desc),
        fieldName: ko.observable('FullDate')
    };

    self.getPageRequest = new $.cdh.get.accountActivity.getPage();
    self.getYearsRequest = new $.cdh.get.accountActivity.getYears();
    self.accountsReassociatedRequest = new $.cdh.get.accounts.listReassociated();
    self.submitMultipleClaimForMarkingRequest = new $.cdh.post.claims.MultipleFxClaimMarkAsPaid();

    self.accounts = ko.observableArray();
    self.accountsLoaded = ko.observable(false);
    self.accountsRequest = $.cdh.accounts.initAccounts(function (accounts) {
        self.accounts(accounts);
        self.accountsLoaded(true);
    });

    self.accountsReassociatedRequest.load().done(data => {
        self.reassociatedAccounts(data.Accounts.map(a => {
            a.DisplayName = data.OriginalAccountsCount > 1 ? a.ExtendedName : a.SimpleName;
            return a;
        }));

        if (self.reassociatedAccounts().length > 0) {
            self.filter.reassociatedAccountId(self.reassociatedAccounts()[0].FlexAcctId);
        }
    });
    
    self.getYearsRequest.load(urlParams.accountId ? {requiredAccountId: urlParams.accountId} : null).done(data => {
        self.years(data.Years);
        self.filter.accountDescription(data.AccountDescription);
        if (data.CurrentYear) {
            self.filter.year(data.CurrentYear);
        }
        self.loadData(1, true);
    });

    self.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([self.accountsRequest, self.getYearsRequest, self.accountsReassociatedRequest]);
    self.aggregatedActivityLoadingStatus = new $.cdh.models.aggregatedLoadStatus([self.getPageRequest, self.submitMultipleClaimForMarkingRequest]);


    self.disableFilters = ko.pureComputed(() => self.getPageRequest.isLoading() || self.submitMultipleClaimForMarkingRequest.isLoading());

    let defaultStatuses = [
        $.cdh.enums.employeeActivitySimplifiedStatus.approved,
        $.cdh.enums.employeeActivitySimplifiedStatus.pending,
        $.cdh.enums.employeeActivitySimplifiedStatus.actionRequired,
        $.cdh.enums.employeeActivitySimplifiedStatus.noAction,
        $.cdh.enums.employeeActivitySimplifiedStatus.denied
    ];

    self.clearSelectedClaimsAndDisableSelect = function () {
        self.selectedClaimsForMarking([]);
        self.submitEnabled(false);
    }

    self.submitEnabled.subscribe(() => updateHeight());

    self.paginatorModel = {
        pageNo: ko.observable(),
        totalPages: ko.observable(),
        onChanged: function (currentPage) {

            // clearing selected claims
            self.clearSelectedClaimsAndDisableSelect();
            self.loadData(currentPage);
        }
    };

    let removeUrlParams = false;
    let firstLoading = true;
    self.loadData = function (currentPage = null, loadAvailableFilters = false) {
        if (self.getYearsRequest.isLoading() || (self.disableFilters())) {
            return;
        }

        self.paginatorModel.pageNo(currentPage || 1);

        let model = {
            pageParams: {
                pageNo: self.paginatorModel.pageNo(),
                pageSize: 10
            },
            searchParams: getSearchModel(),
            sortParams: getSortParams(),
            withAvailableFilters: loadAvailableFilters
        };

        if (removeUrlParams) {
            $.cdh.helper.deleteUrlParam('page');
            $.cdh.helper.deleteUrlParam('claimKey');
            $.cdh.helper.deleteUrlParam('extClaimId');
            $.cdh.helper.deleteUrlParam('transactionKey');
            $.cdh.helper.deleteUrlParam('settlementDate');
            $.cdh.helper.deleteUrlParam('expenseKeys');
            $.cdh.helper.deleteUrlParam('typeCode');
            removeUrlParams = false;
        }

        self.closeAllDropdowns();

        self.getPageRequest.load(model).done(function (data) {
            let items = data.Page.Data;
            self.activityItems(items);

            //clear earlier seleced item  
            self.clearSelectedClaimsAndDisableSelect();
            self.paginatorModel.totalPages(data.Page.TotalPages);

            self.claimForMarkingAvailable(data.isMarkableClaimAvailable);

            if (items.length == 0) {
                firstLoading = false;
                return
            }
                 
            var claimID = items[0].TrackingNumber || items[0].ClaimId;

            if (items.length === 1 && claimID && !self.forceAddReceipt()) {
                self.expandItem(true);
                $.cdh.helper.setUrlParam('claimId', claimID);
            } else {
                self.expandItem(false);
                $.cdh.helper.deleteUrlParam('claimId');
            }

            if (urlParams.claimId && items.length === 1 && !self.forceAddReceipt()) {
                let singleItem = items[0];
                if (singleItem.Date) {
                    let year = moment(singleItem.Date).year();
                    let format = 'MMM D, YYYY';
                    self.filter.year(year);
                    self.filter.dateFrom(moment([year, 0, 1]).format(format));
                    self.filter.dateTo(moment([year, 11, 31]).format(format));
                }
            }

            if (self.forceAddReceipt()) {
                self.forceAddReceipt(false);
                removeUrlParams = true;
            }

            if (data.SimplifiedStatuses) {
                self.simplifiedStatuses(data.SimplifiedStatuses);
                if (self.filter.simplifiedStatus() && (self.simplifiedStatus().length === 0 || self.simplifiedStatus().every(x => x.Value !== self.filter.simplifiedStatus()))) {
                    self.filter.simplifiedStatus(defaultStatuses);
                }
            }

            if (data.ActivityTypes) {
                self.activityTypes(data.ActivityTypes);
                if (self.filter.type() && (self.activityTypes().length === 0 || self.activityTypes().every(x => x.Value !== self.filter.type()))) {
                    self.filter.type(null);
                }
            }

            if (data.AccountDescriptions) {
                self.accountDescriptions(data.AccountDescriptions);
                if (self.filter.accountDescription() && (self.accountDescriptions().length === 0 || !self.accountDescriptions().includes(self.filter.accountDescription()))) {
                    self.filter.accountDescription(null);
                }
            }

            if (data.Statuses) {
                self.statuses(data.Statuses);
                if (self.filter.status() && (self.statuses().length === 0 || self.statuses().every(x => x.Value !== self.filter.status()))) {
                    self.filter.status(null);
                }
            }

            firstLoading = false;

            updateHeight();
        });
    };

    self.skipFilters = function (data, e) {
        e.preventDefault();
        var firstAccountItem = document.querySelector('div.claims-desktop-sheet-table');
        if (!firstAccountItem) {
            return;
        }
        firstAccountItem.focus();
    };

    self.closeAllDropdowns = function () {
        $('div.data-filter__item--opened').removeClass('data-filter__item--opened').removeClass('data-filter__item--opened--left');
    };

    self.toggleHistorical = () => {
        self.filter.isHistorical(!self.filter.isHistorical());
        self.loadData();
    }

    self.saveFilter = () => {
        self.loadData();
    };

    self.changeYear = () => {
        if (!firstLoading) {
            self.loadData(1, true);
        }
    };

    self.filterMarkAsPaid.subscribe(function (newValue) {

        if (newValue) {
            self.filter.needToMarkPaid(true);
            self.loadData();
        }
        else {
            self.filter.needToMarkPaid(null);
            // clear selected claims and disabling submit
            self.clearSelectedClaimsAndDisableSelect();

            // stop multiple updates
            if (!$(event.target)?.attr('data-bind')?.includes('clear'))
                self.loadData();
        }
    });

    self.orderByStatus = () => {
        self.orderBy('FullStatus');
    };
    self.orderBySimplifiedStatus = () => {
        self.orderBy('SimplifiedStatusName');
    };
    self.orderByTypeInfo = () => {
        self.orderBy('TypeInfo');  
    };
    self.orderByDate = () => {
        self.orderBy('FullDate');
    };
    self.orderByAmount = () => {
        self.orderBy('FullAmount');
    };
    self.orderByDescription = () => {
        self.orderBy('ServiceStartDate');
    };
    
    self.orderBy = fieldName => {
        if(self.sort.fieldName() === fieldName) {
            self.sort.direction(self.sort.direction() === $.cdh.enums.sortDirection.asc ? $.cdh.enums.sortDirection.desc : $.cdh.enums.sortDirection.asc);
        } else {
            self.sort.direction($.cdh.enums.sortDirection.asc);
        }
        self.sort.fieldName(fieldName);
        self.loadData();
    };

    self.orderBySimplifiedStatusKeydown = (data, e) => {
        if (e.keyCode === 32) {
            self.orderBySimplifiedStatus();
        }
        return true;
    };
    self.orderByStatusKeydown = (data, e) => {
        if (e.keyCode === 32) {
            self.orderByStatus();
        }
        return true;
    };
    self.orderByTypeInfoKeydown = (data, e) => {
        if (e.keyCode === 32) {
            self.orderByTypeInfo();
        }
        return true;
    };
    self.orderByDateKeydown = (data, e) => {
        if (e.keyCode === 32) {
            self.orderByDate();
        }
        return true;
    };
    self.orderByAmountKeydown = (data, e) => {
        if (e.keyCode === 32) {
            self.orderByAmount();
        }
        return true;
    };
    self.orderByDescriptionKeydown = (data, e) => {
        if (e.keyCode === 32) {
            self.orderByDescription();
        }
        return true;
    };

    self.clearStatus = () => {
        self.filter.status(null);
        self.loadData();
    };
    self.clearSimplifiedStatus = () => {
        self.filter.simplifiedStatus(null);
        self.loadData();
    };
    self.clearType = () => {
        self.filter.type(null);
        self.loadData();
    };
    self.clearDate = () => {
        self.filter.dateFrom(null);
        self.filter.dateTo(null);
        self.loadData();
    };
    self.clearAmount = () => {
        self.filter.amount.from(null);
        self.filter.amount.to(null);
        self.loadData();
    };
    self.clearAccountDescription = () => {
        self.filter.accountDescription(null);
        self.loadData();
    };
    self.clearClaimId = () => {
        self.filter.claimId(null);
        self.loadData();
    };
    self.clearProvider = () => {
        self.filter.provider(null);
        self.loadData();
    };

    self.clear = () => {
        self.filter.year(self.years()[0]);
        self.filter.status(null);
        self.filter.simplifiedStatus(null);
        self.filter.type(null);
        self.filter.dateFrom(null);
        self.filter.dateTo(null);
        self.filter.amount.from(null);
        self.filter.amount.to(null);
        self.filter.accountDescription(null);
        self.filter.claimId(null);
        self.filter.provider(null);

        self.filter.needToMarkPaid(null);
        self.filterMarkAsPaid(false);

        self.loadData(1, true);
    };

    self.exportXlsx = () => {
        if (self.disableFilters())
            return;

        let args = {
            SearchParams: getSearchModel(),
            SortParams: getSortParams()
        };
        
        self.submitFilters = function() {
          self.loadData();  
        };

        window.location.href = $.cdh.routes.accountActivity.export() + '?' + $.param(args);
    };
    

    self.dropdownMenuItemRendered = function (elOrArr, data, itemsKey, options = {}) {
        const element = Array.isArray(elOrArr) ? elOrArr.filter(el => el && el.nodeType && el.nodeType === 1)[0] : element;
        if (!element || !itemsKey || !this[itemsKey]) return;
        const $menuList = $(element).closest('ul');
        const $items = $menuList.find('> li');
        const allItems = this[itemsKey]();
 
        if ($items.length === allItems.length) {
            $.cdh.helper.initMenuAccesibility($menuList[0], options);
            $.cdh.helper.initMenuNavigation($menuList[0], options);
        }
    }

    function getSearchModel() {
        let searchParams = {
            SimplifiedStatuses: self.filter.simplifiedStatus() ? [self.filter.simplifiedStatus()] : defaultStatuses
        }

        if (self.forceAddReceipt()) {
            if (urlParams.claimKey) {
                searchParams.claimKey = urlParams.claimKey;
            }
            if (urlParams.extClaimId) {
                searchParams.claimId = urlParams.extClaimId;
            }
            if (urlParams.transactionKey) {
                searchParams.transactionKey = urlParams.transactionKey;
            }
            if (urlParams.settlementDate) {
                searchParams.settlementDate = urlParams.settlementDate;
            }
            if (urlParams.expenseKeys) {
                searchParams.expenseKeys = urlParams.expenseKeys;
            }
        } else {
            if (self.filter.amount.fromValue() != null) {
                searchParams.AmountFrom = self.filter.amount.fromValue();
            }

            if (self.filter.amount.toValue() != null) {
                searchParams.AmountTo = self.filter.amount.toValue();
            }

            if (self.filter.claimId()) {
                searchParams.ClaimId = self.filter.claimId();
            }

            if (self.filter.provider()) {
                searchParams.Provider = self.filter.provider();
            }

            if (self.filter.type()) {
                searchParams.ActivityType = self.filter.type();
            }

            if (self.filter.status() != null) {
                searchParams.Status = self.filter.status();
            }

            searchParams.NeedToMarkPaid = self.filter.needToMarkPaid();

            if (self.filter.isHistorical()) {
                searchParams.ReassociatedAccountId = self.filter.reassociatedAccountId();
            } else {
                if (self.filter.year() && !(firstLoading && urlParams.claimId)) {
                    searchParams.Year = self.filter.year();
                }
                if (self.filter.accountDescription()) {
                    searchParams.AccountDescription = self.filter.accountDescription();
                }

                if (self.filter.dateFrom()) {
                    let dateFrom = moment(self.filter.dateFrom());
                    if (dateFrom.isValid()) {
                        searchParams.DateFrom = dateFrom.format("YYYY-MM-DD");
                    }
                }

                if (self.filter.dateTo()) {
                    let dateTo = moment(self.filter.dateTo());
                    if (dateTo.isValid()) {
                        searchParams.DateTo = dateTo.format("YYYY-MM-DD");
                    }
                }
            }
        }

        return searchParams;
    }
    
    function getSortParams() {
        return {
            Direction: self.sort.direction(),
            FieldName: self.sort.fieldName()
        }
    }

    self.setPaidOutOfPocket = () => {


        var claimsObjs = self.selectedClaimsForMarking().map(function (x) {
            return { externalClaimId: x };
            }
        );

        $.cdh.helper.alert2({
            title: $.cdh.customLabels.markAsPaidOption,
            message: self.AccountActivityLabel.mark_as_paid_message,
            cancelVisible: true,
            okCssClass: 'button-subm button-width-auto min-width-standard',
            okText: $.cdh.customLabels.markAsPaidOption,
            cancelText: self.AccountActivityLabel.cancel,
            buttonsBlockCssClass: 'popup_buttons--flex',
            okCallback: function () {
                self.submitText(self.AccountActivityLabel.submitting);
                self.submitMultipleClaimForMarkingRequest
                    .load(JSON.stringify(claimsObjs))
                    .done(function () {
                        self.submitText(self.AccountActivityLabel.submit);
                        self.clearSelectedClaimsAndDisableSelect();
                        self.loadData();
                    }).fail(function (xhr) {
                        self.error(xhr.statusText);
                    });
            }
        });

    }
}