import template from "./transactions-list-item.html"
import templateMini from "./transactions-list-item-mini.html"

(function ($, ko) {
    ko.components.register('transactions-list-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new TransactionsListItem(params, widgetInfo.updateHeight);
            }
        }
    });

    ko.components.register('transactions-list-item-mini', {
        template: templateMini,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new TransactionsListItem(params, widgetInfo.updateHeight);
            }
        }
    });
    
    function TransactionsListItem(params, updateHeight) {
        const self = this;
        
        self.model = params.model;

        let trn = params.model.transaction;
        let historicalAccountId = params.model.historicalAccountId;
        
        $.extend(true, self, trn);
        this.sourceTranDesc = `Transactions List. Id: ${self.Id}, Type: ${self.Type}`;;

        self.historicalAccountId = ko.observable(historicalAccountId || null);

        this.shortAccountDescription = trn.AccountTypeDescription && trn.AccountTypeDescription.length > 30 ?
            trn.AccountTypeDescription.substr(0, 30) + '...' :
            trn.AccountTypeDescription;

        self.isCard = trn.TranTypeEnum === $.cdh.enums.tranTypeEnum.card;
        self.isClaim = trn.TranTypeEnum === $.cdh.enums.tranTypeEnum.claim;
        self.isDeposit = trn.TranTypeEnum === $.cdh.enums.tranTypeEnum.deposit;
        self.isOther = !(self.isCard || self.isClaim || self.isDeposit);

        self.showReceiptsBlock = trn.TranTypeEnum !== $.cdh.enums.tranTypeEnum.deposit;
        self.open = ko.observable(false);

        self.ReceiptsCount = ko.observable(trn.ReceiptsCount);
        self.visibleReimbursementDetails = ko.observable(false);
        self.visibleReimbursementDetailsOthers = ko.observable(false);

        if (self.historicalAccountId() != null) {
            self.TrnKey = {
                id: trn.Id,
                settlementDate: trn.SettlementDate,
                seqNumber: trn.SeqNumber,
                accountId: self.historicalAccountId()
            };
            self.details = new $.cdh.get.transaction.detailsReassociated(self.TrnKey);
            self.showReceiptsBlock = false;
        } else {
            self.TrnKey = {id: trn.Id, settlementDate: trn.SettlementDate, seqNumber: trn.SeqNumber};
            self.details = new $.cdh.get.transaction.details60(self.TrnKey);
        }

        self.Receipts = ko.observable(trn.Receipts);
        self.Status = ko.observable();

        self.isDetailsVisible = ko.observable(false);
        self.isDetailsVisible.subscribe(updateHeight);
        
        self.details.isLoading.subscribe(updateHeight);
        
        self.cssClases = ko.pureComputed(function () {
            let status = self.TransactionStatus;
            let showGreenColor = self.ShowGreenColorFlag;
            let cssClass = "color5";
            if (status.indexOf("Approved") > -1 ||
                status.indexOf("Posted") > -1 ||
                status.indexOf("Offset") > -1 ||
                status.indexOf("OnHold") > -1 ||
                status.indexOf("Resolved") > -1 ||
                status.indexOf("Deductible") > -1 ||
                status.indexOf("Paid") > -1 ||
                status.indexOf("AppliedToDeductible") > -1 ||
                status.indexOf("Overpaid") > -1 ||
                status.indexOf("PartiallyApproved") > -1 ||
                status.indexOf("PartiallyPaid") > -1 ||
                status.indexOf("Reversed") > -1 ||
                status.indexOf("Authorized") > -1 ||
                (status.indexOf("Processing") > -1 && showGreenColor)) {
                cssClass = "color";
            } else if (status.indexOf("Pending") > -1 ||
                status.indexOf("Processing") > -1 ||
                status.indexOf("New") > -1 ||
                status.indexOf("Insufficient") > -1 ||
                status.indexOf("Ineligible") > -1 ||
                status.indexOf("Pended") > -1 ||
                status.indexOf("EligibleForReimbursement") > -1) {
                cssClass = "color2";
            } else if (status.indexOf("Denied") > -1) {
                cssClass = "color3";
            } else if (status.indexOf("Reprocessed") > -1) {
                cssClass = "color2";
            }

            if (self.isDetailsVisible())
                cssClass += ' opened';

            return cssClass;
        });

        self.showHoverHelp = ko.pureComputed(function () {
            let status = self.TransactionStatus;
            let showHoverHelpFlag = self.ShowHoverHelpFlag;
            return status.indexOf("Processing") > -1 && showHoverHelpFlag;
        });

        self.translatedStatus = ko.computed(function () {
            var translatedText = $.cdh.languageConstant.getConstant(`Statuses.${self.TransactionStatusDescription}`);
            return translatedText.startsWith('Statuses.') ? self.TransactionStatusDescription : translatedText;
        });

        self.translatedType = ko.computed(function () {
            var translatedText = $.cdh.languageConstant.getConstant(`Type.${self.TypeDescription.toLowerCase()}`);
            return translatedText.startsWith('Type.') ? self.TypeDescription : translatedText;
        });

        let loadDetails = function () {
            self.details.load().done(function (newDetails) {
                if (newDetails.Receipts)
                    self.ReceiptsCount(newDetails.Receipts.length);
                
                self.Receipts(newDetails.Receipts);

                if (self.TransactionStatus.indexOf("Denied") > -1 && newDetails.ErrorDesc && newDetails.ErrorDesc.length > 0)
                    self.denialReason(newDetails.ErrorDesc);
                
                self.isDetailsVisible(true);

                if (newDetails.CheckTraceNumber || newDetails.PaymentId || newDetails.PayoutMethod || newDetails.ReimbursementDate)
                    self.visibleReimbursementDetails(true);

                if (newDetails.CheckTraceNumber || newDetails.PaymentId || newDetails.PayoutMethod || newDetails.ReimbursementDate || newDetails.HSACodeDesc || newDetails.PostingDate)
                    self.visibleReimbursementDetailsOthers(true);


            });
        };

        self.faxCoverUrl = ko.pureComputed(function () {
            return $.cdh.routes.claims.downloadFaxCover({trackingNumber: self.details.data().TrackingNumber, pdf: true})
        });

        self.TrackingNumberExists = ko.pureComputed(function () {
            if (!self.details.hasData())
                return false;

            let t = self.details.data().TrackingNumber;
            return !(t == null || t === "");
        });
        
        self.denialReason = ko.observable(null);
        self.denialReasonExists = ko.pureComputed(function () {
            if (!self.details.hasData())
                return false;

            let t = self.denialReason();
            return !(t == null || t === "");
        });
        self.clickMarkasIneligible = ko.observable(false);
        if ($.cdh.employee.hasAccessToMarkAsInelgible() && self.TranTypeEnum == 3) {
            if (self.TransactionStatus === "Pending" || self.TransactionStatus === "New" || self.TransactionStatus === "Insufficient<br>Documentation") {
                self.clickMarkasIneligible(true);
            }
        }
        let reloadListCallback = params.reloadListCallback;
        this.reloadListCallback = reloadListCallback;
        self.reloadListCallback = params.reloadListCallback;
        self.markAsIneligibleAPI = new $.cdh.post.transactions.setMarkAsIneligible();
        self.uploadReceipt = new $.cdh.models.ItemsUploadReceipt(self);
        self.markAsIneligible = new $.cdh.models.MarkAsIneligible({
            OnSuccessCallback: function () {
                self.markAsIneligibleAPI.load({
                    TransactionKey: trn.Id,
                    SettlementDate: trn.SettlementDate,
                    SequenceNumber: trn.SeqNumber,
                    Status: "ineligible",
                    Year : trn.Date.slice(0,4)
                }).done(function (data) {
                    self.TransactionStatus = "Ineligible";
                    location.reload();
                });
            }
        });

        self.clickToggleWithoutLoading = function () {
            self.open(!self.open());
            self.isDetailsVisible(!self.isDetailsVisible());
        };
        
        self.clickToggleDetails = function () {
            self.open(!self.open());
            if (self.details.isLoading())
                return;
            if (self.details.hasData()) {
                self.isDetailsVisible(!self.isDetailsVisible());
                return;
            }
            loadDetails();
        };

        self.itemHeaderKeyDown = function (data, e) {
            if (e.keyCode === 32 || e.keyCode === 13) {
                $.cdh.isMobile ? self.clickToggleWithoutLoading() : self.clickToggleDetails();
            } else {
                return true;
            }
        };

        self.receipt = function (r) {
            return $.cdh.routes.receipts.image.get(r.FileKey);
        };

        self.openReceiptImage = function (r, event) {
            event.preventDefault();
            event.stopPropagation();
            if ($.cdh.isMobile) {
                //openFancybox(event.target.href, 'iframe');
                $.cdh.helper.showFancyboxLoader();
                $.ajax(event.target.href)
                    .done(function (response, status, xhr) {
                        $.cdh.helper.hideFancyboxLoader();
                        let contentType = xhr.getResponseHeader('content-type');
                        if (contentType.search('pdf') != -1) {
                            let urlText = '/Scripts/pdf-viewer-js/ViewerJS/#' + event.target.href;
                            $.cdh.helper.showPdfViwer(urlText);
                        }
                        if (contentType.search('image') != -1) {
                            openFancybox(event.target.href, 'image');
                        }
                    });
            } else {
                window.open(self.receipt(r));
            }
        };
        
        self.close = params.closeFunc || function () {};
        
        if (params.forceLoadDetails)
            loadDetails();
    }

})(jQuery, ko);