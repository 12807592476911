import template from "./claims-list-item.html"

(function ($, ko) {
    ko.components.register('claims-list-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimsListItem(params, widgetInfo.updateHeight);
            }
        }
    });

    function ClaimsListItem (params, updateHeight) {
        let claim = params.claim;
        let reloadListCallback = params.reloadListCallback;
        let isDirectRequest = params.accountsSettings.isDirectRequest;
        let anyHsaOnly = params.accountsSettings.anyHsaOnly;
        let anyNonHsaOnly = params.accountsSettings.anyNonHsaOnly;
        this.baseParams = params;

        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }
        this.forceAddReceipt = urlParams.page === 'AddReceipt';
        
        $.extend(true, this, claim);
        this.sourceTranDesc = `Claims List. ItemId: ${this.ItemId}, Type: ${this.Type}`;
        
        this.anyHsaOnly = anyHsaOnly;
        this.anyNonHsaOnly = anyNonHsaOnly;
        this.requestReimbursementBuilder = ko.observable();
        this.requestReimbursementTemplate = ko.observable();
        this.isDirectRequest = isDirectRequest;
        this.reloadListCallback = reloadListCallback;

        this.medicalDetails = null;
        this.fxClaimDetails = null;
        
        this.ReceiptsCount = ko.observable(claim.ReceiptsCount);
        this.Receipts = ko.observableArray(claim.Receipts);
        this.PatientId = ko.observable('');
        this.PatientName = ko.observable('');
        this.open = ko.observable(params.expand);
        this.denialReason = ko.observable(null);
        this.isMarkClaim = ko.observable(false);
        
        this.isEnabledPortal = $.cdh.tpa.useExpensePortal;
        
        this.shortAccountDescription = claim.AccountTypeDescription && claim.AccountTypeDescription.length > 30 ? 
            claim.AccountTypeDescription.substr(0, 30) + '...' : 
            claim.AccountTypeDescription;

        this.translatedStatus = ko.computed(function () {
            var statusValue = claim.Status();
            var translatedText = $.cdh.languageConstant.getConstant(`Statuses.${statusValue}`);
            return translatedText.startsWith('Statuses.') ? statusValue : translatedText;
        });

        this.translatedType = ko.computed(function () {
            var typeValue = claim.Type
            var translatedText = $.cdh.languageConstant.getConstant(`Type.${typeValue.toLowerCase()}`);
            return translatedText.startsWith('Type.') ? typeValue : translatedText;
        });

        this.color = ko.pureComputed(function () {
                var color;
                if (this.Actionable()) {
                    color = 'color5';
                } else {
                    color = this.baseColor();
                }
                return color;
            },
            this);

        this.baseColor = ko.pureComputed(function () {
                var c;
                var tp = this.EOBType;
                var status = this.Status();

                if ((tp == 'Claim' || tp == 'MedicalClaim') && (status == 'Submitted' || status == 'Deductible' || status == 'Approved' || status == 'Offset' || status == 'Reprocessed'))
                    c = 'color';
                else if (tp == 'Claim' || tp == 'MedicalClaim' && status.indexOf('Denied') < 0) {
                    c = 'color1';
                } else if (tp == 'Expense' && status == 'Submitted' || status.indexOf('Paid') == 0)
                    c = 'color';
                else if (tp == 'Expense') {
                    c = 'color6';
                } else if (tp == 'ExpenseGroup' || tp == 'FxClaim') {
                    if (status.indexOf('Denied') > -1) {
                        c = 'color3';
                    } else {
                        c = 'color';
                    }
                } else {
                    c = status.indexOf('Paid') > -1 ||
                    status.indexOf('Approved') > -1 ||
                    status.indexOf('OnHold') > -1 ||
                    status.indexOf('On Hold') > -1 ||
                    status.indexOf('Reprocessed') > -1
                        ? 'color'
                        : 'color3';

                    if (status.indexOf('Denied') > -1)
                        c = 'color3';
                }
                return c;
            },
            this);

        this.CanRequestReimbursement = false;
        var eobType = claim.EOBType;
        if (eobType == "Transaction") {
            var details = new $.cdh.get.transaction.details(claim.EOBExtra);
            var newDesc;
            if (claim.Description !== null && claim.Description !== '' && claim.Description !== undefined && claim.Description.startsWith("medical<br>")) {
                newDesc = claim.Description.replace("medical<br>", "");
                this.Description = newDesc;
            }
            this.details = details;
            this.details.Denied = ko.observable(claim.IsDenied);
            details.data.subscribe(function (transaction) {
                var receipts = transaction.Receipts;
                if (receipts) {
                    this.ReceiptsCount(receipts.length);
                    this.Receipts(receipts);
                }

                if (this.Status().indexOf('Denied') > -1 && transaction.ErrorDesc && transaction.ErrorDesc.length > 0){
                    this.denialReason (transaction.ErrorDesc);
                }
            }, this);

        } else {
            this.details = null;
            if ((eobType == 'Expense' || eobType == 'ExpenseGroup' || eobType == 'FxClaim') && claim.EOBAction != null) {
                if($.cdh.employee.canRequestReimbursement()) {
                    this.CanRequestReimbursement = true;
                    
                    this.goToPortal = () => {
                        let portalParam = '';
                        switch(eobType) {
                            case 'FxClaim':
                                portalParam = `fxClaimId=${claim.TrackingNumber}`;
                                break;
                            case 'ExpenseGroup':
                                portalParam = `tcClaimId=${claim.TrackingNumber}`;
                                break;
                            case 'Expense':
                                portalParam = `expenseKey=${claim.ExpenseKey}&originCde=${claim.OriginCde}`;
                                break;
                        }

                        document.location.href = $.cdh.routes.predefined.claims.portal + '?' + portalParam;
                    };
                }

                if (eobType == 'FxClaim') {
                    this.requestReimbursementBuilder = function () {
                        return new $.cdh.models.SubmitClaimWidgetModel(this.reloadListCallback, $.noop, this);
                    }.bind(this);
                    this.requestReimbursementTemplate = function () {
                        return 'template-claim-widget-view';
                    }.bind(this);

                } else if (!this.isDirectRequest()) {
                    this.requestReimbursementBuilder = function () {
                        return new $.cdh.models.RequestReimbursementType(this.reloadListCallback, $.noop, this, true);
                    }.bind(this);
                    this.requestReimbursementTemplate = function () {
                        return 'template-claims-request-reimbursement-type';
                    }.bind(this);

                } else {
                    if (this.anyHsaOnly()) {
                        this.requestReimbursementBuilder = function () {
                            return new $.cdh.models.PaymentsAddPopup(0, this.reloadListCallback, this, $.noop, true);
                        }.bind(this);
                        this.requestReimbursementTemplate = function () {
                            return 'template-payments-add-popup';
                        }.bind(this);
                    } else if (this.anyNonHsaOnly()) {
                        this.requestReimbursementBuilder = function () {
                            return new $.cdh.models.SubmitClaimWidgetModel(this.reloadListCallback, $.noop, this);
                        }.bind(this);
                        this.requestReimbursementTemplate = function () {
                            return 'template-claim-widget-view';
                        }.bind(this);
                    }
                }
            }
        }
        if (eobType === "MedicalClaim") {
            var medicalDetails = new $.cdh.get.claims.medicalDetails();
            this.medicalDetails = medicalDetails;
        }
        else if (eobType == "FxClaim") {
            var fxClaimDetails = new $.cdh.get.claims.fxDetails();
            this.fxClaimDetails = fxClaimDetails;
        }

        this.isPaidOutOfPocket = ko.observable(this.Status() === "Paid Out-Of-Pocket");

        const self = this;

        this.disable = ko.observable(false);

        function setPaidOutOfPocked() {
            $.cdh.helper.alert2({
                title: $.cdh.customLabels.markAsPaidOption,
                message: $.cdh.customLabels.markAsPaidOptionPopupText,
                cancelVisible: true,
                okCssClass: 'button-subm button-width-auto min-width-standard',
                okText: $.cdh.customLabels.markAsPaidOption,
                cancelText: $.cdh.languageConstant.getConstant('button.cancel'),
                buttonsBlockCssClass: 'popup_buttons--flex',
                okCallback: function () {
                    self.disable(true);
                    new $.cdh.post.claims.fxClaimMarkAsPaid()
                        .load({externalClaimId: self.EOBExtra.ExternalClaimId})
                        .done(function () {
                            self.disable(false);
                            self.reloadListCallback();
                        });
                }
            });
        }

        function reversePaidOutOfPocket() {
            $.cdh.helper.alert2({
                title: $.cdh.customLabels.reverseMarkAsPaidOption,
                message: $.cdh.customLabels.reverseMarkAsPaidOptionPopupText,
                cancelVisible: true,
                okText: "Continue",
                okCallback: function () {
                    // TODO: make it possible to display popup from code
                    $('#claim-details-button-' + self.EOBExtra.ExternalClaimId).click();
                }
            });
        }

        this.setPaidOutOfPocket = function (data, event) {
            event.stopPropagation();
            if (self.isPaidOutOfPocket()) {
                reversePaidOutOfPocket();
            } else {
                setPaidOutOfPocked();
            }
        };

        function isTooltipClick(event) {
            if (!event || !event.target) {
                return false;
            }
            return $(event.target).hasClass('js-tooltip');
        }

        this.onToggle = function (data, event) {

            if(isTooltipClick(event)) return;

            var newOpen = !this.open();

            var details = this.details;
            var medicalDetails = this.medicalDetails;
            var fxClaimDetails = this.fxClaimDetails;

            this.open(newOpen);
            if (newOpen) {
                if (details != null && !details.hasData() && !details.isLoading()) {
                    details.load();
                }
                if (this.TrackingNumber) {
                    $.cdh.helper.setUrlParam('claimId', this.TrackingNumber);
                }else{
                    $.cdh.helper.deleteUrlParam('claimId');
                }
            }else{
                $.cdh.helper.deleteUrlParam('claimId');
            }

            if (this.EOBType == 'FxClaim') {
                if (newOpen && fxClaimDetails != null && !fxClaimDetails.hasData() && !fxClaimDetails.isLoading()) {
                    fxClaimDetails.load({ externalClaimId: this.EOBExtra.ExternalClaimId });
                    fxClaimDetails.data.subscribe(function(fx) {
                            var total = fx.Total;
                            if (total != null) {
                                this.PatientId = total.PatientId;
                                this.PatientName = total.PatientName;
                            }

                        }.bind(this),
                        this);
                }
            }
            else if (this.EOBType == 'MedicalClaim') {
                if (newOpen && medicalDetails != null && !medicalDetails.hasData() && !medicalDetails.isLoading()) {
                    medicalDetails.load({ transactionKeys: this.EOBExtra });
                    medicalDetails.data.subscribe(function(eob) {
                            var receipts = eob.Transactions[0].Receipts;
                            if (receipts) {
                                this.ReceiptsCount(receipts.length);
                                this.Receipts(receipts);
                            }
                        }.bind(this),
                        this);
                }
            }
        }.bind(this);

        this.itemKeyDownHandler = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                this.onToggle(data, event);

                return false;
            }

            return true;
        };

        this.receipt = function (r) {
            return $.cdh.routes.receipts.image.get(r.FileKey);
        }.bind(this);

        this.onReceipt = function (r, event) {
            event.preventDefault();
            event.stopPropagation();
            if ($.cdh.isMobile) {
                //openFancybox(event.target.href, 'iframe');
                $.cdh.helper.showFancyboxLoader();
                $.ajax(event.target.href)
                    .done(function(response, status, xhr) {
                        $.cdh.helper.hideFancyboxLoader();
                        var contentType = xhr.getResponseHeader('content-type');
                        if (contentType.search('pdf') != -1) {
                            var urlText = '/Scripts/pdf-viewer-js/ViewerJS/#' + event.target.href;
                            $.cdh.helper.showPdfViwer(urlText);
                        }
                        if (contentType.search('image') != -1) {
                            openFancybox(event.target.href, 'image');
                        }
                    });
            } else {
                window.open(this.receipt(r));
            }
        }.bind(this);

        this.labelButton = ko.pureComputed(function () {
            return this.isMarkClaim() ? 'Updating...' : 'Mark claim as fully paid out-of-pocket';
        }, this);

        this.updateRequest = new $.cdh.post.claims.update();

        this.fullPaidOutOfPocket = function () {
            this.isMarkClaim(true);
            var details = new $.cdh.get.claims.fxDetails();
            details.load({externalClaimId: this.EOBExtra.ExternalClaimId});
            details.data.subscribe(function (fx) {
                var updates = [];
                $.each(fx.Transactions, function (i, t) {
                    updates.push({ClaimLine: t.ClaimLine, Amount: t.RemainingResponsibilityAmount});
                });

                var updateModel = {
                    ExternalClaimId: this.EOBExtra.ExternalClaimId,
                    OutOfPocketAmounts: updates
                };

                this.updateRequest
                    .load(updateModel)
                    .done(function (success) {
                        this.reloadListCallback();
                    }.bind(this))
                    .fail(function () {
                    }.bind(this));
            }.bind(this))

        }.bind(this);

        this.faxCoverUrl = $.cdh.routes.claims.downloadFaxCover({trackingNumber: this.TrackingNumber, pdf: true});
        
        this.open.subscribe(updateHeight);
        this.close = params.closeFunc || function () {};

        this.syncItem = ko.observable().syncWith($.cdh.topics.claims.requiredAttentionItems, true);
        
        this.syncItem.subscribe(function () {
            if (this.syncItem() !== undefined) {
                if ((this.syncItem().isClaim && this.ClaimKey === this.syncItem().claimKey) ||
                    (!this.syncItem().isClaim && this.TransactionId && this.TransactionId === this.syncItem().transactionId)) {
                    this.Status(this.syncItem().itemStatus);
                    this.ReceiptsCount(this.syncItem().receiptsCount);
                    this.Actionable(false);
                    this.Receipts(this.syncItem().receipts);
                }
            }
        }.bind(this));

        this.successCallback = () => {
            $.cdh.helper.deleteUrlParam('page');
        };
    }
    
})(jQuery, ko);