import template from "./claim-fx-details.html"

(function ($, ko) {
    ko.components.register('claim-fx-details', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimFxDetails(params, widgetInfo.updateHeight);
            }
        }
    });

    function ClaimFxDetails(params) {
        const eobSummary = params.model;
        const reloadClaimsListCallback = params.reloadListCallback;

        const isPartiallyPaid = eobSummary.Status() === "Partially Paid";
        const needActionOnSave = eobSummary.Status() === "Paid Out-Of-Pocket" || eobSummary.Status() === "Eligible For Reimbursement" || isPartiallyPaid;
        this.IsReimbursable = ko.observable(eobSummary.EOBAction != null || needActionOnSave);

        this.eobSummary = eobSummary;
        this.eobSummary.lazyServiceEndDate = ko.observable(null);

        this.patientId = ko.observable('');
        this.patientName = ko.observable('');

        this.fxDetails = new $.cdh.get.claims.fxDetails();
        this.fxDetails.load({externalClaimId: eobSummary.EOBExtra.ExternalClaimId});
        this.fxDetails.data.subscribe(function (fx) {

            let sed = null;
            let isReimbursableResult = this.IsReimbursable();

            $.each(fx.Transactions, function (i, t) {
                if (t.ServiceEndDate != null)
                    sed = t.ServiceEndDate;

                if (this.IsReimbursable()) {
                    t.OutOfPocketAmountInitial = t.OutOfPocketAmount;
                    t.ResponsibilityTotal = t.TxnAmtOrig - t.Amount;
                    t.OutOfPocketAmount = ko.observable(t.OutOfPocketAmount);

                    t.OutOfPocketAmountValue = ko.pureComputed(function () {
                        var amount = this.OutOfPocketAmount();
                        return amount != null && amount != "" ? parseFloat(amount) : 0.0;
                    }, t);
                    t.OutOfPocketAmount.subscribe(function (amount) {
                        amount = amount != null && amount != "" ? parseFloat(amount) : 0.0;
                        if (amount < 0.0) {
                            this.OutOfPocketAmount(this.OutOfPocketAmountInitial);
                        } else if (amount > this.ResponsibilityTotal) {
                            this.OutOfPocketAmount(this.ResponsibilityTotal);
                        }
                    }, t);
                    t.RemainingResponsibilityAmount = ko.pureComputed(function () {
                        return this.ResponsibilityTotal - this.OutOfPocketAmountValue();
                    }, t);

                    if (isPartiallyPaid && this.IsReimbursable() && !(Math.abs(t.OutOfPocketAmount()) > 0.001 || Math.abs(t.RemainingResponsibilityAmount()) > 0.001 ))
                        isReimbursableResult = false;

                }
            }.bind(this));
            this.eobSummary.lazyServiceEndDate(sed);

            var oopocket = 0.0, reimb = 0.0, rem = 0.0, chartTotal = 0.0;
            var total = fx.Total;
            if (total != null) {
                chartTotal = total.TxnAmtOrig;
                oopocket = total.OutOfPocketAmount;
                reimb = total.Amount;
                rem = total.RemainingResponsibilityAmount;
                if (this.IsReimbursable()) {
                    total.AmountCounters = ko.pureComputed(function () {
                        var result = {OutOfPocketAmount: 0.0, RemainingResponsibilityAmount: 0.0};
                        for (var i = 0; i < this.length; i++) {
                            result.OutOfPocketAmount += this[i].OutOfPocketAmountValue();
                            result.RemainingResponsibilityAmount += parseFloat(this[i].RemainingResponsibilityAmount());
                        }
                        return result;
                    }, fx.Transactions);
                    total.OutOfPocketAmount = ko.pureComputed(function () {
                        return this.AmountCounters().OutOfPocketAmount;
                    }, total);
                    total.RemainingResponsibilityAmount = ko.pureComputed(function () {
                        return this.AmountCounters().RemainingResponsibilityAmount;
                    }, total);
                }

                this.patientId(total.PatientId);
                this.patientName(total.PatientName);
            }

            if (oopocket == 0.0 && reimb == 0.0 && rem == 0.0)
                reimb = 0.001;

            this.chartTotal = chartTotal;
            this.chartData = [{ value: oopocket, label: $.cdh.languageConstant.getConstant('ClaimDetail.AmountPaidOutOfPacket') },
                { value: reimb, label: $.cdh.languageConstant.getConstant('ClaimDetail.YourBenefitAccountPaid') },
                { value: rem, label: $.cdh.languageConstant.getConstant('field.responsibility') }];

            this.IsReimbursable (isReimbursableResult);

        }.bind(this));

        this.fxTransactionDetails = new $.cdh.get.transaction.details60(eobSummary.EOBExtra.Transaction);
        this.fxTransactionDetails.load();
        this.fxTransactionDetails.data.subscribe(function (transaction) {
            if (this.eobSummary.Status().indexOf('Denied') > -1 && transaction.ErrorDesc && transaction.ErrorDesc.length > 0){
                this.denialReason (transaction.ErrorDesc);
            }
        }, this);

        this.updateRequest = new $.cdh.post.claims.update();

        this.canUpdate = ko.pureComputed(function () {
            return this.fxDetails.hasData() && !this.updateRequest.isLoading();
        }, this);

        this.update = function () {
            var updates = [];
            var updatedLines = $.grep(this.fxDetails.data().Transactions, function (t) {
                return t.OutOfPocketAmountValue() != t.OutOfPocketAmountInitial;
            }.bind(this));
            $.each(updatedLines, function (i, t) {
                updates.push({ClaimLine: t.ClaimLine, Amount: t.OutOfPocketAmountValue()});
            });

            if (updates.length < 1)
                return;

            var updateModel = {
                ExternalClaimId: this.eobSummary.EOBExtra.ExternalClaimId,
                OutOfPocketAmounts: updates
            };

            this.updateRequest
                .load(updateModel)
                .done(function (success) {
                    if (needActionOnSave) {
                        reloadClaimsListCallback();
                        this.close();
                    } else {
                        this.fxDetails.load({externalClaimId: this.eobSummary.EOBExtra.ExternalClaimId});
                    }
                }.bind(this))
                .fail(function () {
                }.bind(this));

        }.bind(this);

        this.close = params.closeFunc || function () {};
    }
    
})(jQuery, ko);